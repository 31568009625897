<template>
    <div class="item-card">
        <img :src="this.img" class="item-card-img" @click="popup = true">
        <div class="item-card-title">{{ this.data.name }}</div>
        <div class="item-card-subtitle">{{ this.data.tags.list }}</div>
        <div class="item-card-time">{{status()}}</div>
        <div v-if="popup" class="popup">
            <div class="popup-card">
                <div class="popup-header">
                    <div class="popup-header-grid">
                        <div class="popup-header-title">{{ this.data.name }}</div>
                        <div class="popup-header-subtitle">{{ status() }}</div>
                        <div class="popup-header-icon" @click="popup = false">
                            <img src="https://s3-eu-west-1.amazonaws.com/intermaps-icons/intermaps/icon_close_3@1x.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="popup-img">
                    <img :src="this.img" class="popup-card-img" @click="popup = true">
                </div>
                <div class="popup-desc"> {{ this.desc }} </div>
                <div class="popup-detail">
                    <div class="popup-detail-title">{{ this.$t('itemCard.text.deals') }}</div>
                    <div class="popup-detail-hours">
                        <ul class="popup-list">
                            <li class="popup-list-title">{{ this.$t('itemCard.text.opening') }}</li>
                            <div v-if="this.data.hoursOfOperation">
                                <li v-for="elem in parseHoursObject(this.data.hoursOfOperation[0])" :key="elem">
                                    {{ elem }}
                                </li>
                            </div>
                            <div v-else>
                                <li> {{ this.$t('itemCard.text.noInfo') }} </li>
                            </div>
                        </ul>
                    </div>
                    <div class="popup-detail-contact">
                        <ul class="popup-list">
                            <li class="popup-list-title">{{ this.$t('itemCard.text.contact') }}</li>
                            <li v-for="elem in parseContactObject(this.contact)" :key="elem.key" :id="elem.key">
                                <span v-if="elem.key != 'website'">{{ elem.value }}</span>
                                <a v-else :href="elem.value">Website</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* --------------------------------------------------------- */
/*                                                           */
/*                                                           */
/*                           POPUP                           */
/*                                                           */
/*                                                           */
/* --------------------------------------------------------- */
.popup-list-title {
    text-transform: uppercase;
    font-size: 2vh;
    font-weight: 600;
}
.popup-list {
    list-style: none;
}
.popup-detail {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: .2fr .8fr;
    height: 40vh;
}

.popup-detail-title {
    grid-column: 1/3;
    grid-row: 1/2;
    background-color: #A5D0DC;
    display: flex;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
    padding-left: 1vw;
}
.popup-detail-hours {
    grid-column: 1/2;
    grid-row: 2/3;
    text-align: start;
}
.popup-detail-contact {
    grid-column: 2/3;
    grid-row: 2/3;
    text-align: start;
}

.popup-card-img {
    max-width: 95%;
    height: 100%;
}
.popup-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
}
.popup-desc {
    display: flex;
    text-align: justify;
    padding: 1vw;
    font-size: 1.75vh;
    font-weight: 500;
}
.popup-header {
    display: flex;
    flex-direction: row;
    height: 10vh;
    width: 100%;
    margin-top: 1vh;
}
.popup-header-grid {
    display: grid;
    grid-template-columns: .8fr .2fr;
    grid-template-rows: 50% 50%;
    height: 100%;
    width: 100%;
}
.popup-header-title {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1vw;
    color: #104f47;
    font-weight: 600;
    font-size: 2vw;
}
.popup-header-subtitle {
    grid-column: 1/2;
    grid-row: 2/3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1vw;
    color: #45845e;
    font-weight: 450;
    font-size: 1.5vw;
}
.popup-header-icon {
    grid-column: 2/3;
    grid-row: 1/3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1.5vw;
    cursor: pointer;
}
.popup {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: default;
}
.popup-card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    height: 90vh;
    width: 50vw;
    overflow-y: scroll;
}

@media (max-width: 1000px) {
    .popup-card {
        height: 80%;
        width: 80%;
    }
    .popup-header-title {
        margin-left: 2vw;
        font-weight: 600;
        font-size: 2.5vw;
    }
    .popup-header-subtitle {
        margin-left: 2vw;
        font-weight: 600;
        font-size: 2vw;
    }
    .popup-desc {
        font-size: 2.5vh;
    }
}
@media (max-width: 500px) {
    .popup-card {
        height: 60%;
        width: 90%;
    }
    .popup-header-title {
        margin-left: 4vw;
        font-weight: 600;
        font-size: 4vw;
    }
    .popup-header-subtitle {
        margin-left: 4vw;
        font-weight: 600;
        font-size: 3.5vw;
    }
    .popup-desc {
        font-size: 1.5vh;
    }
    .popup-detail-title {
        font-size: 2vh;
    }
    .popup-list-title,
    .popup-list {
        font-size: 1.5vh;
    }
}

/* --------------------------------------------------------- */
/*                                                           */
/*                                                           */
/*                           CARD                            */
/*                                                           */
/*                                                           */
/* --------------------------------------------------------- */
.item-card {
    display: flex;
    flex-direction: column;
    height: 35vh;
    width: 100%;
    cursor: pointer;
}
.item-card-img {
    width: 100%;
    height: 25vh;
    resize: none;
    object-fit:cover;
}
.item-card-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    color: #104f47;
    font-weight: 600;
    font-size: 2vh;
}
.item-card-subtitle {
    display: flex;
    align-items: center;
    text-align: start;
    font-weight: 450;
    font-size: 2vh;
}
.item-card-time {
    display: flex;
    align-items: center;
    color: #45845e;
    font-weight: 600;
    text-align: start;
}
@media (max-width: 767px) {
    .item-card {
        height: 20vh;
    }
    .item-card-img {
        width: 100%;
        height: 15vh;
        resize: none;
    }
    .item-card-title {
        font-size: 0.75em;
    }
    .item-card-subtitle {
        font-size: .75em;

    }
    .item-card-time {
        font-size: .75em;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .item-card {
        height: 25vh;
    }
    .item-card-img {
        width: 100%;
        height: 18vh;
        resize: none;
    }
    .item-card-title {
        font-size: 1.3vw;
    }
    .item-card-subtitle {
        font-size: 1.15vw;
    }
    .item-card-time {
        font-size: 1.15vw;
    }
}

@media (min-width: 1024px) and (orientation: landscape) {
    .item-card {
        height: 25vh;
    }
    .item-card-img {
        width: 100%;
        height: 18vh;
        resize: none;
    }
    .item-card-title {
        font-size: 1.25vw;
    }
    .item-card-subtitle {
        font-size: 1.25vw;
    }
    .item-card-time {
        font-size: 1.25vw;
    }
}


@media (min-width: 1200px) and (orientation: landscape) {
    .item-card {
        height: 25vh;
    }
    .item-card-img {
        width: 100%;
        height: 18vh;
        resize: none;
    }
    .item-card-title {
        font-size: 1vw;
    }
    .item-card-subtitle {
        font-size: 1vw;
    }
    .item-card-time {
        font-size: 1vw;
    }
}

@media (min-width: 900px) and (orientation: portrait) {
    .item-card-title {
        font-size: 3vw;
    }
    .item-card-subtitle {
        font-size: 3vw;
    }

    .item-card-time {
        font-size: 3vw;
    }

    .popup-card {

        width: 70vw;
    }

    .popup-header-title {font-size: 3vw;}
    .popup-header-subtitle {font-size: 2.5vw;}
    .popup-list li {font-size: 2.5vw; }
    .popup-list-title {font-size: 3vw; }
    .popup-detail-title {
        font-size: 3vw;
        height: 5vh;
    }

}

</style>

<script>
export default {
    name: 'ItemCard',
    props: {
        data: Object,
        currentDate: String,
        img: String,
        category: String,
        contact: Object,
        desc: String
    },
    data() {
        return {
            popup: false,
        }
    },
    methods: {
        status() {
            const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
            const d = new Date()
            let date = this.currentDate.charAt(0).toUpperCase() + this.currentDate.slice(1) + ' ';
            if (this.data.tags.objectType.includes(347)) {
                if(this.data.hoursOfOperation) {
                    if(this.data.hoursOfOperation[0][days[d.getDay()]]) {
                       date += this.data.hoursOfOperation[0][days[d.getDay()]][0].from + ' - ';
                       date += this.data.hoursOfOperation[0][days[d.getDay()]][0].to;
                    }
                }
                return this.data.liveData.status == 169 ? date : this.$t('itemCard.status.closed')
            } else if (this.data.tags.objectType.includes(346)) {
                if(this.data.hoursOfOperation) {
                    if(this.data.hoursOfOperation[0][days[d.getDay()]] && !this.data.hoursOfOperation[0][days[d.getDay()]].closed) {
                        date += this.data.hoursOfOperation[0][days[d.getDay()]][0].from + ' - ';
                        date += this.data.hoursOfOperation[0][days[d.getDay()]][0].to;
                    }
                }
                return this.data.liveData.checkIn ? date :  this.$t('itemCard.status.closed')
            }
        },
        parseContactObject(object) {
            var ret = []
            Object.entries(object).forEach(function([key, val]) {
                if(val.street)
                    ret.push({key: key, value: val.street + " " + val.streetNumber})
                else
                    ret.push({key: key, value: val})
            });
            return ret
        },
        parseHoursObject(object) {
            var ret = []
            const days = {"monday": "MO",
                   "tuesday": "DI",
                   "wednesday": "MI",
                   "thursday": "DO",
                   "friday": "FR",
                   "saturday": "SA"}
            const cl = this.$t('itemCard.status.closed')
            const hs = this.$t('itemCard.status.hours')
            Object.entries(object).forEach(function([key, val]) {
                var aux = ''
                if(days[key]) {
                    aux += days[key]
                    if(val[0].closed)
                        aux += " " +  cl
                    else
                        aux += " " + val[0].from + " - " + val[0].to + hs
                    ret.push(aux)
                }
            });
            return ret
        }
    }
}
</script>